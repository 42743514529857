import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import FormicopediaHeader from "features/FormicopediaHeader";
import FormicopediaSideBar from "features/FormicopediaSideBar";
import styles from "./styles.module.scss";
import {get} from 'aws-amplify/api';
import {fetchAuthSession} from 'aws-amplify/auth'
import Footer from "../../features/Footer";
import LatestImages from "../../shared/components/LatestImages";

const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

console.log(authToken)

const MainPage = () => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const restOperation = await get({
                apiName: 'stats',
                path: '/stats',
            });
            const response = await restOperation.response;
            const respJson = await response.body.json()
            setData(respJson)
            console.log('GET call succeeded: ', respJson);
        }
        fetchData();
    }, []);

    return (
        <div className={styles.mainContainer}>
            <FormicopediaHeader/>
            <FormicopediaSideBar/>

            {/*<LatestImages/>*/}

            <div className="wrap ms-hero-img-beach ms-hero-bg-info color-dark">
                <div className="container">
                    <h1 className="color-white text-center mb-4">Kilka informacji o formicopedii</h1>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div
                                className="card card-royal card-block text-center wow zoomInUp animation-delay-2">
                                <h2 className="counter">{data.SubForums}</h2>
                                <i className="fa fa-4x fa-coffee color-royal"></i>
                                <p className="mt-2 no-mb lead small-caps">Pod forów tematycznych</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div
                                className="card card-success card-block text-center wow zoomInUp animation-delay-5">
                                <h2 className="counter">{data.StartedTopics}</h2>
                                <i className="fa fa-4x fa-briefcase color-success"></i>
                                <p className="mt-2 no-mb lead small-caps">Rozpoczętych tematów</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div
                                className="card card-danger card-block text-center wow zoomInUp animation-delay-4">
                                <h2 className="counter">{data.PostsWritten}</h2>
                                <i className="fa fa-4x fa-comments-o color-danger"></i>
                                <p className="mt-2 no-mb lead small-caps">Napisanych postów</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div
                                className="card card-info card-block text-center wow zoomInUp animation-delay-3">
                                <h2 className="counter">{data.RegisteredUsers}</h2>
                                <i className="fa fa-4x fa-group color-info"></i>
                                <p className="mt-2 no-mb lead small-caps">Zarejestrowanych użytkowników</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center color-white mw-800 center-block mt-4">
                        <p className="lead lead-lg">Poznaj wspaniały świat małych owadów. Sprawdź nasze forum,
                            bazę wiedzy, oraz stronę na facebooku. Formicopedia to lata zebranych doświadczeń,
                            aktywna społeczność, która działa nieprzerwalnie od 15 lat</p>
                        {/*{% if stats.lead.href is defined %}*/}
                        {/*<a href="{{ stats.lead.href }}" className="btn btn-raised btn-white color-info wow flipInX animation-delay-8">*/}
                        {/*  {{ stats.lead.linkText|raw }}*/}
                        {/*</a>*/}
                        {/*{% endif %}*/}
                    </div>
                </div>
            </div>


            <Footer/>

        </div>
    );
};

export default MainPage;
