import logo from "../../../assets/images/formicopedia8_logo_poziom.png";
import React from "react";

const Hero = () => (
    <>
        <div className="ms-hero-page-override ms-hero-img-city2 ms-hero-bg-primary">
            <div className="container">
                <div className="text-center">
                    <img src={logo} className="animated fadeInDown animation-delay-3" alt="logo"
                         style={{width: "220px"}}/>
                    <p className="lead lead-lg color-medium text-center center-block mt-2 mw-800 text-uppercase fw-300 animated fadeInUp animation-delay-7">
                        Najstarsza
                        <span className="colorStar">społeczność</span> hodowców mrówek
                        <span className="colorStar">w polskim internecie</span>.</p>
                </div>
            </div>
        </div>
    </>
);

export default Hero;
