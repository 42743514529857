const currentYear = new Date().getFullYear();
const Footer = () => (
    <>
        <footer className="ms-footer">
            <div className="container">
                <p>Copyright &copy; formicopedia.org 2005-{currentYear}</p>
            </div>
        </footer>
        <div className="btn-back-top">
            <a href="#" data-scroll id="back-top"
               className="btn-circle btn-circle-primary btn-circle-sm btn-circle-raised ">
                <i className="zmdi zmdi-long-arrow-up"></i>
            </a>
        </div>
    </>
);

export default Footer;
