import React, {useState, useEffect} from "react";
import FormicopediaHeader from "features/FormicopediaHeader";
import FormicopediaSideBar from "features/FormicopediaSideBar";
import Footer from "features/Footer";

import Hero from "shared/components/Hero";


import {generateClient, get} from 'aws-amplify/api';
import {listHistories} from 'graphql/queries';
import TimeLine from "../../shared/components/TimeLine";

const client = generateClient();

const HistoryPage = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const listHistoriesReq = await client.graphql({query: listHistories});
                console.log('Posts retrieved successfully!');
                setData(listHistoriesReq.data.listHistories.items)
            } catch (error) {
                console.log('Error retrieving posts', error);
            }
        }
        fetchData();
    }, []);
    return (
        <>
            <FormicopediaHeader/>
            <FormicopediaSideBar/>
            <Hero/>

            <div className="container">
                <div className="card wow slideInUp">
                    <div className="card-block-big">
                        <h1 className="color-primary">O formicopedii</h1>
                        <p>Pierwsze wzmianki o artykułach na protoplastach formicopedii pochodzą z 13.05.01 oraz z 31
                            styczeń
                            2001 kiedy to strona pozyskała nowy adres mrowisko.of.pl niestety nie jest już dostępna
                            nawet
                            w archiwach internetu :( </p>
                        <h2 className="color-primary">Po co ta strona</h2>
                        <p>Na tej stronie znajdziesz krótką historię tego jak powstawała formicopedia, jaka historia
                            stoi za
                            naszą społecznością</p>
                        <p>Strona ma za zadanie zbierać zmiany w naszej mrówczej społeczności, spisywać jej dokonania
                            :) </p>
                        <p className="lead">Jeżeli masz jakieś informacje które mogą poszerzyć naszą wiedzę lub wiesz że
                            warto dodać
                            to do naszej historii napisz do nas na maila ants@formicopedia.org</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="card card-block mt-6 card-block-big wow fadeInUp"
                     style={{visibility: "visible", animationName: "fadeInUp"}}>
                    <h3 className="text-center fw-500 mb-6">Historia formicopedii</h3>
                    <ul className="ms-timeline-left">
                        {data.map((historia, index) => {
                            return <TimeLine history={historia} index={index}/>
                        })}

                    </ul>
                </div>
            </div>

            <Footer/>
        </>
    );
};

export default HistoryPage;
