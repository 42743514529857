import React from "react";
import ReactDOM from "react-dom";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";


import "./i18n";
import "vendor/plugins.min.css";
import "vendor/preload.min.css";
import "vendor/style.light-green-500.min.css";
import { Amplify} from 'aws-amplify';
import amplifyconfig from "./aws-exports.js";
import MainPage from "./pages/MainPage";
import HistoryPage from "./pages/Foundation/history";
import ErrorPage from "./pages/Error";
Amplify.configure(amplifyconfig);

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage/>,
        errorElement: <ErrorPage />
    },
    {
        path: "/historia",
        element: <HistoryPage/>,
    },

]);

ReactDOM.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
    document.getElementById("root")
);
