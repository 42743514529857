import React from 'react';


export default function TimeLine({history, index}) {
    let historyDate = new Date(history.Date);
    return <>
        <li className="ms-timeline-left-item wow fadeInUp" id={"history-" + index}
            style={{visibility: "visible", animationName: "fadeInUp"}}>
            <h4>{history.Title}</h4>
            <div className="ms-timeline-left-left">
                <h4 className="color-primary">Nowy pomocnik</h4>
                <time className="ms-timeline-left-time">{historyDate.toLocaleDateString('pl-PL', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })}</time>
            </div>
            <span className="ms-timeline-left-city">Mrówkolandia</span>
            <div dangerouslySetInnerHTML={{__html: history.Content}}/>
        </li>
    </>
}