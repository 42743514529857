import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import FormicopediaHeader from "features/FormicopediaHeader";
import FormicopediaSideBar from "features/FormicopediaSideBar";
import styles from "../MainPage/styles.module.scss";
import Footer from "../../features/Footer";
import {useRouteError} from "react-router-dom";


const ErrorPage = () => {
    const error = useRouteError();
    console.error(error);
    const {t} = useTranslation();

    return (
        <div className={styles.mainContainer}>
            <FormicopediaHeader/>
            <FormicopediaSideBar/>
            <div className="row">
                <div className="col-md-8 col-md-offset-2">
                    <div className="card animated fadeInUp animation-delay-7 color-primary withripple">
                        <div className="card-block-big color-dark">
                            <div className="text-center">
                                <h1 className="color-primary">Error 500</h1>
                                <h2>Internal Server Error</h2>
                                <i>{error.statusText || error.message}</i>
                                <p className="lead lead-sm">Something has gone wrong we are trying to fix it.
                                </p>
                                <a href="index.html" className="btn btn-primary btn-raised">
                                    <i className="zmdi zmdi-home"></i> Go Home</a>
                            </div>
                        </div>
                    </div>
                    <div className="card animated fadeInUp animation-delay-9 color-primary withripple">
                        <div className="card-block-big color-dark">
                            <h2 className="color-primary">Search</h2>
                            <p className="lead">Use the search engine to browse our website and find what you want.</p>
                            <div className="form-group label-floating">
                                <label className="control-label" for="addon2">Search in page...</label>
                                <input type="text" id="addon2" className="form-control" />
                            </div>
                            <a href="index.html" className="btn btn-primary btn-raised btn-block">
                                <i className="zmdi zmdi-search"></i> Search</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default ErrorPage;
