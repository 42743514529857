import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import en from "shared/translations/en.json";
import pl from "shared/translations/pl.json";
import de from "shared/translations/de.json";
import es from "shared/translations/es.json";
import fr from "shared/translations/fr.json";
import it from "shared/translations/it.json";
import pt from "shared/translations/pt.json";
import rs from "shared/translations/rs.json";

i18n.use(initReactI18next).init({
	fallbackLng: "pl",
	debug: true,
	react: {
		useSuspense: false,
	},
	resources: {
		en: {
			translation: en,
		},
		pl: {
			translation: pl,
		},
		de: {
			translation: de,
		},
		es: {
			translation: es,
		},
		fr: {
			translation: fr,
		},
		it: {
			translation: it,
		},
		pt: {
			translation: pt,
		},
		rs: {
			translation: rs,
		}
	}
});

export default i18n;
